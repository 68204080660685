<template lang="pug">
main(v-if='response', data-view) 
  section.hero
    img(src='@/assets/IlluBlastFAQ_Ampoule.png', height='300')
    h1 Foire aux questions
  section.collapse-section
    template(v-for='(group, index) in response.data.question_answer_group')
      .collapsible-box(v-b-toggle='`collapse${index}`', :key='index')
        .collapsible-box__header(header-tag='header')
          prismic-rich-text.collapsible-box__header__question(:field='group.question')
          img(src='@/assets/chevron-right.svg')
        b-collapse.mt-2(:id='`collapse${index}`', role='tabpanel', accordion='page-accordion')
          prismic-rich-text.collapsible-box__answer(:field='group.answer')
</template>

<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'

export default {
  name: 'FAQ',
  metaInfo() {
    return {
      title: this.response && this.response.data.title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.response && this.response.data.description
        }
      ]
    }
  },
  components: {
    BCollapse
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      response: null
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle('faq')
      this.response = response
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/mixins';

@import '@/styles/vars';

main {
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  padding-top: 0;
}

.hero {
  // display: flex;
  // background-color: rgba($green, 0.4);
  // border-radius: 20px;
  // margin-bottom: 3rem;
  // padding: 2rem;

  display: flex;
  flex-direction: column;
  margin: 0 -1.5rem;
  padding: 1.5rem;
  background-color: rgba($lightBlue, 0.4);
  border-radius: 0 0 20px 20px;
  margin-bottom: 3rem;
  align-items: center;
  // justify-content: space-between;

  @include medium-screen {
    position: relative;
    flex-direction: row;
    margin: 0;
    margin-bottom: 3rem;
    padding: 4rem 0;
    background-color: transparent;
    align-items: center;
    justify-content: space-around;
    padding-left: 5rem;

    &::after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba($lightBlue, 0.4);
      right: 0;
      margin: -2rem (-$mainHorizontalPaddingMedium) -2rem 0;
      z-index: -1;
      border-radius: 20px 0 0 20px;
    }
  }

  @include large-screen {
    padding: 5rem 0;
    padding-left: 5rem;

    &::after {
      width: 100%;
      margin: -2rem (-$mainHorizontalPaddingLarge) -2rem 0;
    }
  }

  h1 {
    font-size: 1.5rem;
  }
}

.collapse-section {
  @include medium-screen {
    width: 80%;
    margin: 0 auto;
  }
}

.collapsible-box {
  padding: 1rem;
  border: 1px solid rgba($darkGrey, 0.2);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;

    img {
      transform: rotate(90deg);
      transition: transform 0.2s ease-in-out;
      margin-left: 1rem;
    }

    &__question {
      font-weight: 700;
      font-size: 1.05rem;
    }
  }

  &.not-collapsed {
    .collapsible-box__header {
      img {
        transform: rotate(-90deg);
      }
    }
  }

  &::v-deep {
    p {
      margin: 0;
    }

    ul {
      margin-left: 1rem;
      list-style-type: disc;
      font-family: 'Libre Franklin', sans-serif;
    }
  }
}
</style>
